/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateNewUsername(username) {
  const re = /^[a-zA-Z0-9]*$/;
  return re.test(String(username).toLowerCase());
}

export function validateNewPassword(password) {
  return password.length >= 8;
}

export function validatePhoneNumber(phone_number) {
  const regex = /^08[1-9][0-9]{6,10}$/;
  if (phone_number.length >= 9 && phone_number.length <= 13) {
    return regex.test(phone_number);
  }
  return false;
}
