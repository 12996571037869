export const isObject = item =>
  typeof item === "object" && !Array.isArray(item);

export const mergeObj = (target, source, deep = false) => {
  if (deep || !Object.assign) {
    const isDeep = prop => {
      return (
        isObject(source[prop]) &&
        target !== null &&
        Object.hasOwnProperty.call(target, prop) &&
        isObject(target[prop])
      );
    };

    const replaced = Object.getOwnPropertyNames(source)
      .map(prop => ({
        [prop]: isDeep(prop)
          ? mergeObj(target[prop], source[prop], deep)
          : source[prop]
      }))
      .reduce((a, b) => ({...a, ...b}), {});

    return {
      ...target,
      ...replaced
    };
  } else {
    return Object.assign(target, source);
  }
};

export function removeElement(el) {
  if (typeof el.remove !== "undefined") {
    el.remove();
  } else if (typeof el.parentNode !== "undefined" && el.parentNode !== null) {
    el.parentNode.removeChild(el);
  }
}
