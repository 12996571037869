import Vue from "vue";
import VueRouter from "vue-router";
import {removeToken, setToken} from "@/utils/auth";
import {removeUserId, setUserId} from "@/utils/user";
import Auth from "@/api/auth";

Vue.use(VueRouter);
const DEFAULT_DP_STATUS = ["account"];
const routes = [
  {
    path: "/",
    beforeEnter: () => {
      window.location = process.env.VUE_APP_EDUKASYSTEM;
    }
  },
  {
    path: "/auth",
    name: "auth",
    redirect: "/auth/login",
    component: () => import(/* webpackChunkName: "Auth" */ "@/views/auth"),
    meta: {
      must_not_login: true
    },
    children: [
      {
        path: "login",
        component: () =>
          import(
            /* webpackChunkName: "Login" */ "@/views/auth/components/login"
          ),
        name: "login"
      },
      {
        path: "register",
        component: () =>
          import(
            /* webpackChunkName: "Register" */ "@/views/auth/components/register"
          ),
        name: "register"
      }
    ]
  },
  {
    path: "/auth/logout",
    name: "logout",
    beforeEnter: async to => {
      removeToken();
      removeUserId();
      window.gapi.load("auth2", function() {
        window.gapi.auth2.init().then(() => {
          let auth2 = window.gapi.auth2.getAuthInstance();
          auth2.signOut().then(function() {
            window.location = `${to.query.redirect}?redirect=${to.query.path}&options=${to.query.options}`;
          });
        });
      });
    }
  },
  {
    path: "/auth/:token",
    name: "auth-login-free",
    beforeEnter: async to => {
      const {token} = to.params;
      const {redirect, options} = to.query;

      window.gapi.load("auth2", function() {
        window.gapi.auth2.init().then(() => {
          let auth2 = window.gapi.auth2.getAuthInstance();
          auth2.signOut().then(async function() {
            const res_verif = await Auth.validateToken(token).then(
              res => res.data.data
            );

            const {id} = res_verif;

            setToken(token);
            setUserId(id);

            if (redirect && redirect !== "undefined") {
              const urlRedirect =
                redirect.charAt(redirect.length - 1) === "/"
                  ? redirect
                  : redirect + "/";
              if (options) {
                const objQuery = JSON.parse(options);
                const keyQuery = Object.keys(objQuery);
                let query = "";
                keyQuery.forEach((el, idx) => {
                  query += `${el}=${objQuery[el]}`;
                  if (idx < keyQuery.length - 1) query += "&";
                });

                window.location = `${urlRedirect}auth/${token}?${query}`;
              } else {
                window.location = `${urlRedirect}auth/${token}`;
              }
            } else {
              window.location = `${process.env.VUE_APP_EDUKASYSTEM}/auth/${token}`;
            }
          });
        });
      });
    }
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () =>
      import(
        /* webpackChunkName: "forget-password" */ "@/views/ForgetPassword"
      ),
    meta: {
      must_not_login: true,
      type_navbar: "none",
      no_loading: true,
      dp_status: [...DEFAULT_DP_STATUS]
    },
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        next({name: "forget-password-form", params: {token: to.query.token}});
      } else {
        next();
      }
    }
  },
  {
    path: "/forget-password/:token",
    name: "forget-password-form",
    component: () => import("@/views/ForgetPasswordForm"),
    meta: {
      must_not_login: true,
      type_navbar: "none",
      no_loading: true,
      dp_status: [...DEFAULT_DP_STATUS]
    },
    beforeEnter: async (to, from, next) => {
      if (!to.params.token) {
        next({name: "forget-password"});
      } else {
        const res = await Auth.validateToken(to.params.token).then(
          res => res.data.data
        );
        if (res) {
          next();
        } else {
          next({name: "forget-password"});
        }
      }
    }
  },
  {
    path: "/change-password",
    name: "change-password",
    meta: {
      requires_auth: true,
      type_navbar: "none",
      no_loading: true,
      dp_status: [...DEFAULT_DP_STATUS]
    },
    component: () =>
      import(/* webpackChunkName: "change-pass" */ "@/views/ChangePassword")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
