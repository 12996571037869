import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import MessageProgrammatic from "./components/Message";
import "buefy/dist/buefy.css";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import "./permission";
import "./icon"; // icon
import "./icons"; // icon

Vue.use(MessageProgrammatic);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: FontAwesomeIcon,
  defaultIconPack: "fas"
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
