import router from "./router/index";

import {requireToken} from "@/utils/middleware";
import {getToken} from "@/utils/auth";
import Time from "@/api/time";
import {setLSObjectWithExpiry, getLSObjectWithExpiry} from "@/utils/general";

router.beforeEach(async (to, from, next) => {
  const token = getToken();

  // Handle UTM
  const {
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    utm_term
  } = to.query;

  const now = await Time.getTime().then(res => res.data.timestamp);
  if (utm_campaign || utm_medium || utm_source || utm_content || utm_term) {
    const existedUTM = getLSObjectWithExpiry("utm", now);
    const expired_at = now + 3 * 24 * 3600 * 1000;
    // const expired_at = now + 0;
    if (existedUTM.expired) {
      setLSObjectWithExpiry(
        "utm",
        {
          utm_campaign: utm_campaign || "",
          utm_medium: utm_medium || "",
          utm_source: utm_source || "",
          utm_content: utm_content || "",
          utm_term: utm_term || ""
        },
        expired_at
      );
    }
  }

  // LOADING PER PAGE BY VUEX
  if (token) {
    // if has token
    await requireToken(token); // Check token validation first
    if (to.matched.some(record => record.meta.requires_auth)) {
      // if require auth, check auth
      next();
    } else {
      if (to.matched.some(record => record.meta.must_not_login)) {
        // url for havent login
        if (to.query.redirect) {
          if (to.query.options) {
            const objQuery = JSON.parse(to.query.options);
            const keyQuery = Object.keys(objQuery);
            let query = "";
            keyQuery.forEach((el, idx) => {
              query += `${el}=${objQuery[el]}`;
              if (idx < keyQuery.length - 1) query += "&";
            });
            window.location = `${to.query.redirect}/auth/${token}?${query}`;
          } else {
            window.location = `${to.query.redirect}/auth/${token}`;
          }
        } else {
          next({path: "/"});
        }
      } else {
        // otherwise, next
        next();
      }
    }
  } else {
    /* has no token*/
    if (to.matched.some(record => record.meta.requires_auth)) {
      next({path: "/auth/login"});
    } else {
      next();
    }
  }
});

// LOADING PER PAGE BY VUEX
// router.afterEach(() => {
//   // finish progress bar
//   // setTimeout(() =>  store.dispatch('State/setLoadingStatus'), 1000);
// })
