import axios from "axios";
import {getToken} from "@/utils/auth";
import {getUserId} from "@/utils/user";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: parseInt(process.env.MAX_TIMEOUT_TIME) // request timeout
  // timeout: 1 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.authorization = `BEARER ${getToken()}`;
      config.headers["Client-ID"] = getUserId();
      config.headers["Client-Type"] = "user";
      return config;
    }
    return config;
  },
  error => {
    // do something with request error
    console.log("error");
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log("error");
    console.log({...error}); // for debug
    return Promise.reject(error);
  }
);

export default service;
