import { removeToken } from "@/utils/auth";
import { setUserId, removeUserId } from "@/utils/user";
import Time from "@/api/time";
import Auth from "@/api/auth";

const reset = () => {
  removeToken();
  removeUserId();
  window.location.pathname = "/auth/login";
};

export async function requireToken(token) {
  try {
    const res_verif = await Auth.validate(token).then(res => res);
    const { exp, id } = res_verif.data.data;

    const now = await Time.getTime().then(res => res.data.timestamp);
    const date_exp = exp * 1000;
    if (date_exp > now) {
      setUserId(id);
    } else {
      reset();
    }
  } catch (err) {
    const { response } = err;
    if (response && (response.status === 500 || response.status === 422)) {
      reset();
    }
  }
}
