import service from "@/utils/request";
import {getToken} from "@/utils/auth";

export default class Auth {
  static baseGroupURL = "/auth";

  static async loginByEmail(data) {
    const resp = await service.post(`${this.baseGroupURL}/email`, data);
    return resp;
  }

  static async loginByUsername(data) {
    const resp = await service.post(`${this.baseGroupURL}/username`, data);
    return resp;
  }

  static async loginByGoogle(data) {
    const resp = await service.post(`${this.baseGroupURL}/google`, data);
    return resp;
  }

  static async validate() {
    const resp = await service.post(`${this.baseGroupURL}/verification`, {
      token: getToken()
    });
    return resp;
  }

  static async validateToken(token) {
    const resp = await service.post(`${this.baseGroupURL}/verification`, {
      token
    });
    return resp;
  }

  static async blacklistToken(token) {
    const res = await service.post(`${this.baseGroupURL}/blacklist`, {token});
    return res;
  }

  // Trial user (login)
  static async loginTrial() {
    const res = service.post(`${this.baseGroupURL}/free`);
    return res;
  }
}
