<script>
import {isExternal} from "@/utils/validation";

export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass);
    },
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return [this.$style.svg__icon, this.className];
      } else {
        return this.$style.svg__icon;
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`
      };
    }
  }
};
</script>

<template>
  <div
    v-if="isExternal"
    :style="styleExternalIcon"
    :class="[$style.svg__external__icon, $style.svg__icon]"
    v-on="$listeners"
  ></div>
  <svg v-else :class="svgClass" aria-hidden="true" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg>
</template>

<style lang="scss" module>
.svg__icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg__external__icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
